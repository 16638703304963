import React ,{useState}from 'react'
import '../style/manage.css'
import {Button} from 'rsuite'
import AllPosts from './manage/AllPosts'
import NewAd from './manage/NewAd'

const ManageMyAdd = () => {
  const [visible, setVisible] = useState(true)

  return (
    <div className="container">
      <div className="header">
         {visible ? 
         <Button onClick={()=>setVisible(!visible)} appearance='primary'>New Add</Button>
         :
         <Button onClick={()=>setVisible(!visible)} appearance='primary'>All Posts</Button>
         }
      </div>
      <div className="body">
         {visible ? 
            <AllPosts/>
         :
            <NewAd />
         }
      </div>
    </div>
  )
}

export default ManageMyAdd