import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./Screens/Home";
import Login from './Screens/Auth/Login'
import User from './Screens/User'
import ManageMyAdd from "./Screens/ManageMyAdd";
import EPost from './Screens/update/EPost'
const Router = () => {
  return (
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/User" element={<User />} />
        <Route path="/ManageMyAdd" element={<ManageMyAdd />} />
        <Route path="/EPost/:id" element={<EPost />} />
      </Routes>
  );
};

export default Router;