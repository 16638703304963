import {  useRef, useState, useEffect } from 'react';
import {
    Container,
    Message,
    Form,
    ButtonToolbar,
    Button,
    Panel,
    FlexboxGrid,
    InputPicker
  } from 'rsuite';
  import {useAuth} from '../../Context/AuthContext';
  import {useNavigate} from 'react-router-dom';


  const Login = () => {
    const {_SignIn} = useAuth();
    const [loading,setLoading] = useState(false);
    const [error,setError] = useState('');
    const [success,setSuccess] = useState('');
    const [email,setEmail] = useState('')
    const [password,setPassword] = useState('');
    const navigate = useNavigate()
    const handleRoute = (route) => navigate(route)



    const formData =  (formValue)=>{
      setEmail(formValue.email)
      setPassword(formValue.password)
    }

    const onError = (err)=>{
      setError(err);
      setLoading(false);
    }

    const onSuccess = (res)=>{
      setError('');
      setSuccess(res)
      setLoading(false);
      navigate('/')
    }


    const handleSubmit = ()=>{
      setLoading(true);
      try{
        setError('');
        _SignIn(email,password,onError,onSuccess);
      }catch{
        setError('Please Try Again');
        setLoading(false);
      }
    }


    return(
    <div className="login-container">
      <Container>
          <FlexboxGrid justify="center" style={{height:'calc(100vh)',alignItems:'center'}}>
            <FlexboxGrid.Item colspan={8} style={{minWidth:'350px'}}>
              {error?
               <Message showIcon type="error">{error}</Message>
               :
               success?
               <Message showIcon type="success">{success}</Message>
               :
               ''
              }
              <Panel header={<h3 style={{display:'flex' , justifyContent: 'center' , fontFamily:'pacifo' , color:'#8f482e'}}>Silver Maharaja</h3>} shaded bordered >
                <Form fluid onChange={formData}>
                  <Form.Group>
                    <Form.ControlLabel style={{color:'black' , fontSize:'16px'}}>Username</Form.ControlLabel>
                    <Form.Control name="email" />
                  </Form.Group>
                  <Form.Group>
                    <Form.ControlLabel style={{color:'black' , fontSize:'16px'}}>Password</Form.ControlLabel>
                    <Form.Control name="password"  type="password" autoComplete="off" />
                  </Form.Group>
                  <Form.Group>
                    <ButtonToolbar style={{marginTop:'10px'}}>
                      <Button appearance="primary"  loading={loading} type='button' onClick={()=>handleSubmit()} style={{background:'#8f482e'}}>Login</Button>
                    </ButtonToolbar>
                  </Form.Group>
                </Form>
              </Panel>
            </FlexboxGrid.Item>
          </FlexboxGrid>
      </Container>
    </div>
  );
    }
  export default Login;
