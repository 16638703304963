import axios from 'axios';
let instance;
axios.defaults.headers['X-API-KEY'] = sessionStorage.getItem('token');
if (process.env.NODE_ENV === "production") {
    if(navigator.onLine){
      instance = axios.create({baseURL: 'https://silvermaharajaltd.com/api'});
    }else{
      instance = axios.create({baseURL: 'https://silvermaharajaltd.com/api'});
    }
}else{
    if(navigator.onLine){
      instance = axios.create({baseURL: 'http://localhost:9000/api'});
    }else{
      instance = axios.create({baseURL:'http://localhost:9000/api'});
    }
 }

export default instance;
