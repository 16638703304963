import React, {useState, useEffect} from 'react'
import axios from '../../Components/axios';
import { useParams } from 'react-router';
import {Loader} from 'rsuite'
import EditPost from './EditPost';
const EPost = () => {
  const {id} = useParams()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState()
  localStorage.setItem('cid', id)

  const Data = ()=>{
    axios.get(`/shoesinfo/getshoes/${id}`).then(({data})=>{
      setTimeout(() => {
        setLoading(false)
      }, 500);
      setData(data[0])
      console.log(data)
    })
  }
  useEffect(() => {
    Data()
  }, [10])


  return loading ? 
    <div style={{width:'100%' , height:'100vh' , display:'flex' , justifyContent:'center' , alignItems:'center'}}>

      <Loader size="lg" />
    </div>
  :
    <EditPost data={data} />

}

export default EPost;
