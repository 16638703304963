// import logo from "./logo.svg";
import "./App.css";
import Home from "./Home";
import "rsuite/dist/rsuite.min.css";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./Context/AuthContext";
import './style/Fonts.css'
import ScrollToTop from "./Components/ScrollToTop";
function App() {
  return (
    <BrowserRouter>
    <ScrollToTop />
    <AuthProvider>
      <Home />
    </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
