import React, { useEffect, useState } from 'react'
import banner from '../../asset/banner.jpeg'
import '../../style/card.css'
import { styled } from '@mui/material/styles';
import {Modal, Form, Button } from 'rsuite'
import {Grid } from '@mui/material';
import Paper from '@mui/material/Paper';
import axios from '../../Components/axios';
import TrashIcon from '@rsuite/icons/Trash';
import EditIcon from '@rsuite/icons/Edit';
import { useNavigate } from 'react-router-dom';

const Item = styled(Paper)(({ theme }) => ({
   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
   ...theme.typography.body2,
   padding: theme.spacing(1),
   textAlign: 'center',
 color: theme.palette.text.secondary,
 }));


const AllPosts = () => {
  const navigation = useNavigate()
  const handleRoute = (route)=>navigation(route)
  const [data, setData] = useState([  {
    name: '',
    company: '',
    size: '',
    description: '',
    id: 0,
    sid: 0,
    filepath: ''
   }
  ])
  const [visible, setVisible] = useState(false)

  const getData = () => {
    axios.post('/shoesinfo/allshoes').then(({data})=>{
      console.log(data)
      if(data.length > 0) {
        setData(data)
      }
    })
  }
  
  useEffect(()=>{
    getData()
  },[visible.status])

  const handleClose = ()=> setVisible({status:false})
  const handleDelete = ()=> {
    axios.delete(`/shoesinfo/delete/${visible.id}`).then(({data})=>{
      console.log(data)
      setVisible({status:false})
    })
  }
  const handleEdit = (id)=> {
    handleRoute(`/EPost/${id}`)
  }


  return (
    <div className='container'>

        <div style={{width:'100%' , display:'flex' , justifyContent: 'space-evenly' , marginTop:"20px"}}>
              <Grid container lg={12} xs={12} sm={12} md={12} display={'flex'} justifyContent={'space-around'} >
              {data.map((item) =>(
                <Grid item xs={11} sm={9} md={3.8} lg={3.3} marginTop={'20px'} >
                  <Item style={{padding:'0'}}>
                    <div className="cardBody" >
                      <div className="cardImageContainer">
                        <img src={item.filepath} className='cardImage'/>
                      </div>
                      <div className='bottomContainer'>
                        <div className='shoesContainer'>
                          <p className='shoesName'>{item.name}</p>
                          <div style={{marginRight:'10px'}}>
                            <TrashIcon className='icons' style={{color:'red'}} onClick={() => setVisible({status:true, id:item.idd})} />
                            <EditIcon className='icons' style={{color:'blue'}} onClick={() => handleEdit(item.idd)}/>
                          </div>
                        </div>
                        <div className='sizesContainer'> 
                          <p style={{marginLeft:'5px'}}>Sizes : </p>
                          <div className='sizes'> 
                            {item.size}
                            </div>
                          </div>
                          <div className='sizesContainer'> 
                            <p style={{marginLeft:'5px'}}>Description : {item.description}</p>
                          </div>
                        </div>
                      </div>
                    </Item>
                  </Grid> 
                ))}
                </Grid>
        </div>   

      {/* delete modal */}
      <Modal
        open={visible.status}
        onClose={handleClose}

      >
      <Modal.Body>
        <Form.ControlLabel className="font" style={{marginTop:'20px'}}>Are you sure to delete this post?</Form.ControlLabel>
        
      </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleDelete}  appearance="primary">
            Ok
          </Button>
          <Button onClick={handleClose}  appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default AllPosts
