
import React,{createContext,useContext,useState} from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../Components/axios';
const AuthContext = createContext();

export const useAuth = ()=>{
    return useContext(AuthContext)
}



export const AuthProvider = ({children}) => {
    const [currentUser,setCurrentUser] = useState(false);
    const navigate = useNavigate();

    const _SignIn = (email,password,onError,onSuccess)=>{
      console.log(email, password)
      axios.post('/auth/authenticateuser',{email:email,password:password})
      .then((res)=>{
        let data = res.data;
        console.log(res)
        if(data.token){
          localStorage.setItem("currentUser",JSON.stringify(data.result));
          localStorage.setItem('uid',data.result.id)
          sessionStorage.setItem('token',data.token)
          localStorage.setItem('adminPassword',password)
          setCurrentUser(true);
          onSuccess('عملیه اجراء شوه')
          setTimeout(() => {
            navigate('/')
          }, 1000);
        }else if(data.status === 404){
          onError('Please Try Again!')
        }
      })
     }


    const _SignUp = (data,onSuccess,onError)=>{
      axios.post('auth/registeruser',{data:data})
      .then(async(res)=>{
        console.log(res.data)
        if(res.data.insertId){
          const uid = res.data.insertId
          // let data = res.data
          localStorage.setItem("currentUser",JSON.stringify(data));
          localStorage.setItem('uid', uid)
          setTimeout(() => {
            
            navigate('/')
          }, 1000);
          
          onSuccess('ستاسو اکاونټ په بریالیتوب سره راجسټر سوه !');
        }else{
          onError('Please Try Again!')

        }
      })
  }

    const _LogOut = ()=>{
      localStorage.removeItem("currentUser");
      sessionStorage.removeItem('token');
      localStorage.removeItem('uid');
      localStorage.setItem('aid' , "null")
      navigate('/')
    }



    const value ={
      currentUser,
      _SignIn,
      _SignUp,
      _LogOut
    }

  return (
    <AuthContext.Provider value={value}>
        {children}
    </AuthContext.Provider>
  )
}

