import React, {useState, useEffect} from 'react';
import { Carousel} from 'rsuite';
import { styled } from '@mui/material/styles';
import {Grid } from '@mui/material';
import Paper from '@mui/material/Paper';
import banner from '../asset/banner.jpeg'
import banner2 from '../asset/banner2.jpeg'
import banner3 from '../asset/banner3.jpg'
import banner4 from '../asset/banner4.jpeg'
import '../App.css'
import {Button , Form , Input} from 'antd'
import axios from '../Components/axios'
import TextArea from 'antd/es/input/TextArea';
import location from '../asset/location.png'
import phone from '../asset/phone.png'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
color: theme.palette.text.secondary,
}));

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});

const Home = () => {
  const [data, setData] = useState([  {
    name: '',
    company: '',
    size: '',
    description: '',
    id: 0,
    sid: 0,
    filepath: ''
   }
  ])

  const onFinish = (values)=>{
    axios.post('/shoesinfo/sendmail', values).then(({data})=>{
        console.log(data)
    })
}
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const validateEmail = (_, value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!value || emailRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject('Please enter a valid email address');
  };

  const getData = () => {
    axios.post('/shoesinfo/allshoes').then(({data})=>{
      console.log(data)
      if(data.length > 0) {
        setData(data)
      }
    })
  }
  
  useEffect(()=>{
    getData()
  },[])


  return (
    <div >
      <Carousel className="custom-slider" shape='bar' autoplay style={styles.carousel}>
        <img src={banner2} height="400" style={{objectFit:'cover'}}/>
        <img src={banner3} height="400" style={{objectFit:'cover'}}/>
        <img src={banner4} height="400" style={{objectFit:'cover'}}/>
      </Carousel>

    {/* cards */}


      <div style={{width:'100%' , marginTop:'20px'}}>
        <div>
             <p style={{fontFamily:'pacifo' , fontSize:'30px' , textAlign:'center' , color:'#8f482e'}}>New Collection</p>
        </div>       
        <div style={{width:'100%' , display:'flex' , justifyContent: 'space-evenly' , marginTop:"20px"}}>
          <Grid container lg={12} xs={12} sm={12} md={12} display={'flex'} justifyContent={'space-around'} >
          {data.map((item) =>(
            <Grid item xs={11} sm={9} md={3.8} lg={3.3} marginTop={'20px'} >
              <Item style={{padding:'0'}}>
                <div className="cardBody" >
                  <div className="cardImageContainer">
                    <img src={item.filepath} className='cardImage'/>
                  </div>
                  <div className='bottomContainer'>
                    <div className='shoesContainer'>
                      <p className='shoesName'>{item.name}</p>
                    </div>
                    <div className='sizesContainer'> 
                      <p style={{marginLeft:'5px'}}>Sizes : </p>
                      <div className='sizes'> 
                        {item.size}
                        </div>
                      </div>
                      <div className='sizesContainer'> 
                        <p style={{marginLeft:'5px'}}>Description : {item.description}</p>
                      </div>
                    </div>
                  </div>
                </Item>
              </Grid> 
            ))}
            </Grid>
        </div>   
      </div>

    {/* contact us */}

        <div style={{width:'100%' , marginTop:'40px'}}>
            <div>
                  <p style={{fontFamily:'pacifo' , fontSize:'30px' , textAlign:'center' , color:'#8f482e'}}>Contact Us</p>
            </div> 

            <div style={{width:"100%" , marginTop:'20px'}}>
            <Grid container lg={12} xs={12} sm={12} md={12} display={'flex'} justifyContent={'space-around'} >
                <Grid item xs={9} sm={9} md={9} lg={9}>
                <div style={{ background: 'whitesmoke', height: '400px', alignItems: 'center', width: '100%', borderRadius: '5px', display: 'flex', justifyContent: 'space-between' }}>
                    <Form
                      name="contactForm"
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                      style={{ width: '100%', height: '100%', justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                    >
                      <div style={{ width: '80%' }}>
                        <Form.Item
                          // label="Name"
                          name="name"
                        >
                          <Input style={{ width: '100%', height: '30px' }} placeholder='Ahmad'/>
                        </Form.Item>
                      </div>

                      <div style={{ width: '80%' }}>
                        <Form.Item
                          // label="Email"
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter your email!',
                            },
                            {
                              validator: validateEmail,
                            },
                          ]}
                        >
                          <Input style={{ width: '100%', height: '30px' }} type="email" placeholder='example@gmail.com' />
                        </Form.Item>
                      </div>

                      <div style={{ width: '80%' }}>
                        <Form.Item
                          // label="Message"
                          name="message"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter your message!',
                            },
                          ]}
                        >
                          <TextArea
                            allowClear
                            showCount
                            maxLength={100}
                            placeholder="Give us a message"
                            style={{
                              height: 150,
                              resize: 'none',
                            }}
                          />
                        </Form.Item>
                      </div>

                      <Form.Item>
                        <Button type="primary" style={{background:'#8f482e'}} htmlType="submit">
                          Submit
                        </Button>
                      </Form.Item>
                    </Form>
              </div>

                </Grid> 
          </Grid>
            </div>
        </div>

    </div>
  );
};

export default Home;

const styles = {
  container: {
    height: '100vh',
    display: 'flex',
  },
  carousel: {
    width: '100%',
    height: '500px',
  },
  carouselImage: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
};
