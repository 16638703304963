import React ,{useEffect} from "react";
import {
  Button,
  Nav,
  Drawer
} from "rsuite";
import Router from "./Router";
import { useNavigate } from "react-router-dom";
import {useAuth} from './Context/AuthContext'
import "./App.css";
import { BiUserCircle} from "react-icons/bi";
import {Grid , Typography} from '@mui/material'
import {MdOpenInNew} from 'react-icons/md'
import { Popover, Space } from 'antd'
import { FaPhoneSquareAlt } from "react-icons/fa";
import { FaMapLocationDot } from "react-icons/fa6";

const Home = () => {
  const {_LogOut} = useAuth()
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState()
  const [opene, setOpene] = React.useState(false);
  const hide = () => {
    setOpene(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpene(newOpen);
  };
  const [size, setSize] = React.useState("xs");
  const [user, setUser] = React.useState()
  const [placement, setPlacement] = React.useState();
  const handleRoute = (route) => navigate(route);
  const handleOpen = (key) => {
    setOpen(true);
    setPlacement(key);
  };
  useEffect(() => {
    if(localStorage.getItem('uid')){
      console.log('hlllo')
      setUser(true)
    }else{
      setUser(false)


  }}, [user,open]);
  useEffect(()=>{
    if(localStorage.getItem('currentUser')){
      const {username} = JSON.parse(localStorage.getItem('currentUser'))
      setName(username)
    }else{
      setName('B A W A R')
    }
  })
  

    const content = (
      <div>{!user ? 
        <>
  
        <div ><Button onClick={()=>handleRoute('/Login') } style={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center', height:'30px', borderRadius:'50px', background:"#f76100", color:'white'}}>Login</Button></div>
        </>
      :
      <div>
        <div style={{marginTop:'10px'}} className="popover" onClick={() => handleRoute(`/ManageMyAdd`)}>
         <span className="popover">
             <div style={{ width:'25%' , display:'flex'}}><MdOpenInNew style={{height:'20px' , width:'20px'}}/></div>
                <p style={{fontWeight:'bolder'}}>Manage Ads</p>
            </span>
        </div>
        <div style={{width:'100%'}}>
          <Button onClick={()=>_LogOut()} style={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center', height:'30px', borderRadius:'50px', border:'1px solid #f76100', color:'#f76100',marginTop:'10px' , background:'white'}} trigger='click'>Log Out</Button>
        </div>
      </div>
    }
    </div>
  );


  const NavBar = ()=>{
    return(
      <Nav style={{ backgroundColor: "#7B3F00", display:'flex', justifyContent:'space-between'  , position:'sticky' , top:'0' , zIndex:'10' , height:'50px' , alignItems:"center"}}>  
        <div style={{display:'flex' , alignItems:'center' , marginLeft:'30px'}}>
          <p style={{marginRight:'10px' , color:'white' , fontFamily:'cairo' , fontSize:'20px', cursor:'pointer'}} onClick={()=>handleRoute('/')}>Silver Maharaja</p>
        </div>      
            <Space wrap>
              <Popover content={<p onClick={hide}>{content}</p>} title={user ? <p style={{textAlign:'center' , fontSize:'20px' , fontWeight:"bold"}}>{name}</p> :         
              <div style={{display:'flex' , alignItems:'center' , justifyContent:'center'}}>
                  <p style={{marginRight:'10px'  , fontFamily:'cairo'}}>Silver Maharaja LTD</p>
              </div>} trigger="click"       
              open={opene}
              onOpenChange={handleOpenChange}
              >
              <div style={{display:'flex' , alignItems:'center'}}>
                <BiUserCircle onClick={()=>setUser(!user)} size={30} style={{color:'white' , marginRight:'10px' , marginTop:'2px' , cursor:'pointer'}}/>
              </div>
              </Popover>
            </Space>
      </Nav>
    )
  }
  return (
    <div>
      {NavBar()}

      <body className="Body">
        <Router />
      </body>
      <footer className="footer">
          <div className="footer-section">
            <div className="contact-info">
              <h4>Kandahar</h4>
              <p style={{display:'flex' , alignItems:'center'}}><FaPhoneSquareAlt style={{marginRight:'20px' , fontSize:'20px'}}/> + 93 (0) 700 155 859</p>
              <p style={{display:'flex' , alignItems:'center'}}><FaMapLocationDot style={{marginRight:'20px' , fontSize:'20px'}}/> Shah Bazaar, Dubai Market, Second Floor, Shop#</p>
              <p style={{display:'flex' , alignItems:'center' , fontFamily:'scha'}}><FaMapLocationDot style={{marginRight:'20px' , fontSize:'20px'}}/> شاه بازاره، دوبی مارکیټ، دویم منزل، دوکان نمبر  </p>
            </div>
            <div className="contact-info">
              <h4>Kabul</h4>
              <p style={{display:'flex' , alignItems:'center'}}><FaPhoneSquareAlt style={{marginRight:'20px' , fontSize:'20px'}}/> + 93 (0) 799 172 696</p>
              <p style={{display:'flex' , alignItems:'center'}}><FaMapLocationDot style={{marginRight:'20px' , fontSize:'20px'}}/> Kocha Temor Shahi, New Sadr Market, Tahkawi Floor Shop#64</p>
              <p style={{display:'flex' , alignItems:'center' , fontFamily:'scha'}}><FaMapLocationDot style={{marginRight:'20px' , fontSize:'20px'}}/> کوچه تیمورشاهی، نوی صدر مارکیټ، منزل تهکوی، دوکان نمبر ۶۴ </p>
            </div>
          </div>
    {/* <Grid lg={12} md={12} sm={12} xs={12}  display={' '} justifyContent={'center'}><Typography variant="body2" color="white">&copy; 2023 All Rights Reserved With Silver Maharaja LTD</Typography></Grid> */}
    </footer>
    </div>
  );
};

export default Home;
