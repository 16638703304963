import React, {useState, useEffect, useRef} from 'react'
import {Input, Form, InputGroup, Button, Modal , Loader} from 'rsuite'
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import axios from '../../Components/axios';
import camera from '../../asset/images/camera.svg'
import AttachmentIcon from '@rsuite/icons/Attachment';
import { toFile } from '../..//Components/toFile';
import styled from 'styled-components';
import WebCam from 'react-webcam'
import {styled as styleded} from '@mui/material/styles'
import { useNavigate } from 'react-router-dom';
import {Grid } from '@mui/material';


const EditPost = (props) => {
  const navigatee = useNavigate()
  const [formValue, setFormValue] = useState({})
  const webRef = useRef(null)
  const fileUploadRef = useRef(null)
  const [show, setShow] = useState(false)
  const [file, setFile] = useState('')


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(formValue)
    setFormValue((prevProps) => ({
        ...prevProps,
    [name]: value
    }));
  };



   const toDataURL = url => fetch(url)
   .then(response => response.blob())
   .then(blob => new Promise((resolve, reject) => {
     const reader = new FileReader()
     reader.onloadend = () => resolve(reader.result)
     reader.onerror = reject
     reader.readAsDataURL(blob)
   }))
 
 
   useEffect(()=>{
     setFormValue(props.data);
     toDataURL(props.data.filepath)
       .then(dataUrl => {
         setFile(dataUrl);
       })
   },[])


   const ShowImage = ()=>{
      let take = webRef.current.getScreenshot();
      setFile(take)
  }
  
  const previewFile = (e)=> {
    const reader = new FileReader();
    reader.onloadend = () => {
      setFile(reader.result);
    };
    reader.readAsDataURL(e.target.files[0]);
  }
  
    const handleSubmit = async(event) => {
          const data = new FormData();
          data.append('data',JSON.stringify(formValue));
          data.append('images',await toFile(file,'picture'));
          axios.put(`/shoesinfo/edit/${formValue.idd}`,data,
          {headers:
            {
             "Content-Type": "multipart/form-data",
            }
        }).then(({data})=>{
         console.log(data);
         navigatee('/ManageMyAdd')
        })
    };

const Item = styleded(Paper)(({ theme }) => ({
   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
   ...theme.typography.body2,
   padding: theme.spacing(1),
 color: theme.palette.text.secondary,
}));


  return (
   <div style={{width: '70%', height: '70%', marginTop:'30px' , marginRight:'auto' , marginLeft:'auto'}}>
      <Grid container lg={12} md={12} sm={12} xs={12}>
         <Grid lg={6} xs={12} sm={12} md={6} display={'flex'} justifyContent={'center'}>
         <Form >
            <InputGroup style={{marginTop:'15px', width:'320px'}} className="input" onChange={handleInputChange}>
               <Input  name="name" value={formValue.name} placeholder="Name" style={{borderRadius: '5px'}}/>
            </InputGroup>
            <InputGroup style={{marginTop:'15px', width:'320px'}} className="input" onChange={handleInputChange}>
               <Input  name="size" value={formValue.size} placeholder="Size" style={{borderRadius: '5px'}}/>
            </InputGroup>
            <InputGroup style={{marginTop:'15px', width:'320px'}} className="input" onChange={handleInputChange}>
               <Input  name="company" value={formValue.company} placeholder="Company" style={{borderRadius: '5px'}}/>
            </InputGroup>
            <InputGroup style={{marginTop:'15px', width:'320px'}} className="input" onChange={handleInputChange}>
               <Input  name="description" value={formValue.description} placeholder="Description" style={{borderRadius: '5px'}}/>
            </InputGroup>
            <Button onClick={handleSubmit} style={{marginTop:"20px" , width:'100%'}}>Save</Button>
         </Form>
         </Grid>
         <Grid lg={6} xs={12} sm={12} md={6}  display={'flex'} justifyContent={'center'} marginTop={'10px'}>
         <CameraContainer>
            <Camera>
            <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
            {show ?
            <>
            <WebCam
            name="image"
            ref={webRef}
            height={'100%'}
            width={'100%'} />

            </>
            :
            <>
            {!file ?
            <ImagePlaceholder
            src={camera}
            />
            :
            <Image
            src={file}
            />
            }
            </>
            }
            </div>
          <Controls>
          {show?

          <Button onClick={()=>{ShowImage();setShow(!show);}} style={{width:'100%',borderRadius:0}}>Take</Button>
          :
          <View>
          <Button onClick={()=>{fileUploadRef.current.click()}} appearance="primary" color="blue" style={{width:'100%',borderRadius:0}}>
            <AttachmentIcon style={{width:20,height:20}}/>
          </Button>
          <Button onClick={()=>{setShow(!show)}} appearance="primary" color="blue" style={{width:'100%',borderRadius:0}}>
            <img src={camera} style={{filter: 'brightness(0) invert(1)'}}/>
          </Button>
        </View>
        }
          </Controls>
          </Camera>

          </CameraContainer>
          <input type='file' name='image' ref={fileUploadRef} onChange={previewFile} hidden/>
      </Grid>
    </Grid>
   </div>
  )
}

export default EditPost


const style = {
  width:'100%',
  marginTop:'10px'
};

const Container = styled.div`
  display:grid;
  grid-template-columns:1fr 1fr;
  height:calc(100vh - 160px);
`

const FormContainer = styled.div`
`

const View = styled.div`
  display: flex;
  justifyContent:space-between;
  alignItems:center;
  width:100%;
`

const Group = styled.div`
  display:flex;
  flex-wrap:wrap;
  flex-direction:column;
  justify-content:space-between;
  width:100%;
`

const CameraContainer = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
`

const Camera = styled.div`
  display:grid;
  grid-template-rows:1fr 0.1fr;
  width: 250px;
  height: 300px;
  border:1px solid grey;
  background:#f7f7f7;
  overflow:hidden;
`

const Image = styled.img`
  height:100%;
  width:100%;
  object-fit:cover;
`

const ImagePlaceholder = styled.img`
  height:30%;
  width:30%;

`

const Controls = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
`
