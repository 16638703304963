import React , {useState, useEffect} from 'react'
import {
  Container,
  Message,
  Form,
  Button,
  Panel,
  FlexboxGrid,
  Input,
  InputGroup
} from 'rsuite';
import {message} from 'antd'
import {useAuth} from '../Context/AuthContext'
// import '../style/SignUp.css';
import '../App.css'
import {useNavigate} from 'react-router-dom'
const styles = {
  marginTop: 20,
  radioGroupLabel: {
      padding: '8px 9px 8px 10px',
      display: 'inline-block',
      verticalAlign: 'middle',
      background:'#f7f7fa',
      borderRadius:5,
      marginLeft:'10px',
    }
};

const User = () => {
  const navigate = useNavigate();
  const handleRoute = (route) => navigate(route);
  // const [contextHolder] = message.useMessage()
  const {_SignUp} = useAuth();
  const [ loading ,setLoading] = useState(false);
  const [error,setError] = useState('');
  const [success, setSuccess] = useState('')
  // const [term, setTerm ] = useState('')
  const [data, setData] = useState({
    username: "",
    phone: "",
    email: "",
    password: "",
    role: "normal",
    status: "normal",
    date: ""
  });
  const onSuccess = (res)=>{
    setError('');
    setSuccess(res)
    setLoading(false);
    // navigate('/')
  }
  const onError = (err)=>{
    setError(err);
    setLoading(false);
  }



  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prevProps) => ({
      ...prevProps,
      [name]: value
    }));
  };

  const handleSubmit = ()=>{
    setLoading(true);
    if (!data.email.endsWith('@gmail.com')) {
      onError('عملیه اجراء نه شوه');
    } else {
      try{
        setError('');
        _SignUp(data,onSuccess,onError,()=>{
          setLoading(false);

        });
        
      }catch{
        setLoading(false);
      }
    }
  }


  return(
    <div className="login-container">
    {/* {contextHolder} */}
      <Container dir="rtl">
          <FlexboxGrid justify="center" style={{height:'calc(100vh)',alignItems:'center'}}>
            <FlexboxGrid.Item colspan={8} style={{minWidth:'350px'}}>
              <Panel header={<h3 style={{display:'flex' , justifyContent: 'center'}}>راجیسټر پاڼه</h3>} shaded bordered >
              {error?
               <Message showIcon type="error">{error}</Message>
               :
               success?
               <Message showIcon type="success">{success}</Message>
               :
               ''
              }
                <Form fluid>
                    <InputGroup style={styles}  onChange={handleInputChange}>
                          <InputGroup.Addon>
                                نوم
                          </InputGroup.Addon>
                          <Input name="username"  value={data.username}/>
                    </InputGroup>
                    <InputGroup style={styles} onChange={handleInputChange}>
                          <InputGroup.Addon>
                                مبایل شمیره
                          </InputGroup.Addon>
                          <Input name="phone" value={data.phone}/>
                    </InputGroup>
                    <InputGroup style={styles} onChange={handleInputChange}>
                          <InputGroup.Addon>
                                ایمیل
                          </InputGroup.Addon>
                          <Input name="email" type="email" value={data.email}/>
                    </InputGroup>
                    <InputGroup style={styles}  onChange={handleInputChange}>
                        <InputGroup.Addon>
                            پاسورډ
                        </InputGroup.Addon>
                        <Input name="password" value={data.password}/>
                    </InputGroup>
                    <InputGroup style={styles}  onChange={handleInputChange}>
                        <InputGroup.Addon>
                              تاریخ
                        </InputGroup.Addon>
                        <Input name="date" value={data.date}/>
                    </InputGroup>
                   <Button color="blue" type="submit" onClick={handleSubmit} appearance="primary" style={{margin:'30px 0 0 0 ' , width:'90px' , justifyContent:'center'}}>ثبت</Button>
                </Form>
              </Panel>
            </FlexboxGrid.Item>
          </FlexboxGrid>
      </Container>
    </div>
  )
}

export default User

